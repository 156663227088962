<!--
// nuxt-ui/components/base/BaseLivePopup.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import EmbedTwitch from "~/nuxt-ui/components/Shared/twitch/EmbedTwitch.vue";

type Props = {
  classWindowPosition: string;
};

defineProps<Props>();

const competitionStore = useCompetitionStore();
const webappStore = useWebappStore();
const { locale } = useI18n();
const { isKings, isQueens } = useWebContext();
const { findRegionInfoBySlug } = regionUiService();
const seenRegion = ref<string | undefined>(competitionStore.region?.name);

const isClosed = ref<boolean>(false);
const isMobile = ref<boolean | undefined>();

onMounted(async () => {
  isMobile.value = window.innerWidth < 1025;
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 1025;
  });

  seenRegion.value = findRegionInfoBySlug(webappStore.twitchInfo?.stream.region, "name");
});

const showTwitchEmbed = computed(() => {
  return !!webappStore.twitchInfo && webappStore.twitchInfo?.live && !isMobile.value;
});
</script>

<template>
  <div
    v-if="showTwitchEmbed"
    :key="competitionStore?.region?.slug"
    :class="classWindowPosition"
    class="z-20 fixed"
  >
    <div
      v-if="!isClosed"
      class="flex flex-col rounded-lg overflow-hidden relative max-w-max border border-gray-700 bg-gray-900"
    >
      <div class="absolute right-3 top-3 cursor-pointer" @click="isClosed = !isClosed">
        <svg
          class="w-5 h-5 text-gray-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="black"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>
      <div
        v-if="seenRegion && seenRegion !== 'Spain'"
        class="absolute top-3 left-3 py-1 px-2 bg-gray-700 text-xs font-bold rounded-lg"
      >
        {{ seenRegion }}
      </div>

      <!--      <iframe
        v-if="webappStore.twitchInfo?.stream.region === 'espana'"
        width="400"
        height="300"
        src="https://www.youtube.com/embed/live_stream?channel=UCtwslQPB_xJfaFuZv0OETNw&autoplay=1&mute=1"
        allowfullscreen
      ></iframe>-->
      <embed-twitch
        :channel="webappStore.twitchInfo?.stream.account"
        :width="400"
        :height="300"
        :is-live="webappStore.twitchInfo?.live"
      ></embed-twitch>
    </div>
  </div>
</template>

<style scoped></style>
