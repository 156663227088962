<!--
// nuxt-ui/components/pages/region-home/GermanyLandingHome.vue
-->
<script setup lang="ts">
import HubSpotForm from "~/nuxt-ui/components/pages/region-tickets/HubSpotForm.vue";
import BannerJoinTheDraft from "~/nuxt-ui/components/Shared/landings/BannerJoinTheDraft.vue";
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
//import { isFutureDate } from "~/src/Shared/utils";

const bgGermanyLanding = "/img/landings/bg-landing-germany.jpg";

const { locale, t } = useI18n({
  useScope: "local",
});
const { region } = useCompetitionStore();

const showJoinDraft = ref(true);

const withVideo = true;

const formId = "32d72976-fa5f-4b90-822b-6eb02b9dc280";
const linkDraft = "https://germany.apply.kingsleague.pro/";

const regionSocial = region?.social;
const socialProfiles = {
  twitter: regionSocial ? regionSocial?.find(s => s.name === "twitter")?.url : undefined,
  discord: regionSocial ? regionSocial.find(s => s.name === "discord")?.url : undefined,
  instagram: regionSocial ? regionSocial.find(s => s.name === "instagram")?.url : undefined,
  twitch: regionSocial ? regionSocial.find(s => s.name === "twitch")?.url : undefined,
  tiktok: regionSocial ? regionSocial.find(s => s.name === "tiktok")?.url : undefined,
  youtube: regionSocial ? regionSocial.find(s => s.name === "youtube")?.url : undefined,
  facebook: regionSocial ? regionSocial.find(s => s.name === "facebook")?.url : undefined,
};

/*onMounted(() => {
  if (isFutureDate("2025-03-31T22:59:59Z")) {
    showJoinDraft.value = true;
  }
});*/
</script>

<template>
  <section
    class="mx-auto min-h-screen md:min-h-[calc(100vh-225px)] bg-cover bg-right-top bg-no-repeat md:py-20"
    :style="`background-image:url('${bgGermanyLanding}');`"
  >
    <div class="max-w-6xl mx-auto">
      <div class="flex flex-col md:flex-row justify-between gap-6 items-center px-0 md:px-4">
        <banner-join-the-draft
          v-if="showJoinDraft && withVideo"
          draft-form-link="https://germany.apply.kingsleague.pro/"
          league-logo="/img/league-logos/klg-full.png"
          league-logo-alt="Kings League Germany"
          :button-text="t('landing.joinDraft')"
          :claim-before-button="t('landing.newRegions.claimBeforeButton')"
          only-mobile
        ></banner-join-the-draft>

        <div class="md:max-w-lg px-4 md:px-0">
          <i18n-t
            keypath="landing.newRegion.title"
            tag="h1"
            class="text-primary text-3xl uppercase font-bold font-[termina]"
          >
            <template #countryPrefixTitle>
              {{ t("landing.newRegions.countryPrefixTitle.female") }}
            </template>
            <template #leagueCountry>
              {{ t("germany") }}
            </template>
          </i18n-t>

          <p class="mt-8">
            {{ t("landing.newRegion.firstSentence") }}
          </p>

          <i18n-t keypath="landing.newRegion.secondSentence" tag="p" class="mt-4">
            <template #teamsNumber>
              {{ 8 }}
            </template>
            <template #countryPrefixSentence>
              {{ t("landing.newRegions.countryPrefixSentence.female") }}
            </template>
            <template #leagueCountry>
              {{ t("germany") + "s" }}
            </template>
          </i18n-t>

          <p class="font-bold">
            {{ t("landing.germany.signup") }}
          </p>

          <div class="min-h-[378px] max-w-md">
            <hub-spot-form :form-id="formId" src="//js-eu1.hsforms.net/forms/embed/v2.js" />
          </div>
        </div>

        <div v-if="!withVideo" class="order-first md:order-2 w-full h-full flex justify-center">
          <banner-join-the-draft
            v-if="showJoinDraft"
            :draft-form-link="linkDraft"
            league-logo="/img/league-logos/klg-full.png"
            league-logo-alt="Kings League Germany"
            :button-text="t('landing.joinDraft')"
            :claim-before-button="t('landing.newRegions.claimBeforeButton')"
          ></banner-join-the-draft>
          <img
            v-else
            src="/img/league-logos/klg-full.png"
            alt="Kings League Germany"
            class="my-4 max-w-[225px] md:max-w-[350px]"
          />
        </div>

        <div
          v-if="withVideo"
          class="px-4 md:px-0 md:flex flex-col items-center md:items-start justify-center h-auto max-w-full mb-6 md:mb-0"
        >
          <iframe
            height="350"
            width="100%"
            class="max-w-[771px] max-h-[235px] sm:max-h-none aspect-video rounded-3xl outline-none"
            src="https://www.youtube-nocookie.com/embed/NuW1VXBKRH8?si=H1f3Bk5W3ZePSien&rel=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div class="self-center mt-4 hidden md:block">
            <social-icons :social-profiles="socialProfiles"></social-icons>
          </div>

          <div class="hidden md:block self-center min-w-[50%]">
            <p class="mt-4 md:mt-6 !mb-2 text-sm sm:text-base md:text-lg text-center">
              {{ t("landing.newRegions.claimBeforeButton") }}
            </p>
            <nuxt-link :to="linkDraft" external target="_blank" class="block btn btn-emphasis text-center">
              <span class="font-bold font-['Termina'] leading-none uppercase">{{
                t("landing.joinDraft")
              }}</span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "landing.newRegion.title": "Das Original kommt endlich {countryPrefixTitle} {leagueCountry}!",
    "landing.newRegion.firstSentence": "Die Kings League – der Mix aus Fußball und Entertainment, mit Regeln, die alles verändern und das Spiel auf ein neues Level bringen.",
    "landing.newRegion.secondSentence": "{teamsNumber} Teams, angeführt von den bekanntesten Streamern {countryPrefixSentence} {leagueCountry}. Volle Leidenschaft. Höchstes Tempo. Null Langeweile.",
    "landing.newRegions.countryPrefixTitle.male": "nach",
    "landing.newRegions.countryPrefixTitle.female": "nach",
    "landing.newRegions.countryPrefixSentence.male": "",
    "landing.newRegions.countryPrefixSentence.female": "",
    "landing.newRegions.claimBeforeButton": "Werde ein Spieler der Kings League!",
    "germany": "Deutschland",
    "landing.germany.signup": "Trag dich jetzt ein – und erfahre als Erster, was in der Kings League Germany passiert.",
    "landing.joinDraft": "Trage dich jetzt ein"
  },
  "es": {
    "landing.newRegion.title": "Das Original kommt endlich {countryPrefixTitle} {leagueCountry}!",
    "landing.newRegion.firstSentence": "Die Kings League – der Mix aus Fußball und Entertainment, mit Regeln, die alles verändern und das Spiel auf ein neues Level bringen.",
    "landing.newRegion.secondSentence": "{teamsNumber} Teams, angeführt von den bekanntesten Streamern {countryPrefixSentence} {leagueCountry}. Volle Leidenschaft. Höchstes Tempo. Null Langeweile.",
    "landing.newRegions.countryPrefixTitle.male": "nach",
    "landing.newRegions.countryPrefixTitle.female": "nach",
    "landing.newRegions.countryPrefixSentence.male": "",
    "landing.newRegions.countryPrefixSentence.female": "",
    "landing.newRegions.claimBeforeButton": "Werde ein Spieler der Kings League!",
    "germany": "Deutschland",
    "landing.germany.signup": "Trag dich jetzt ein – und erfahre als Erster, was in der Kings League Germany passiert.",
    "landing.joinDraft": "Trage dich jetzt ein"
  },
  "it": {
    "landing.newRegion.title": "Das Original kommt endlich {countryPrefixTitle} {leagueCountry}!",
    "landing.newRegion.firstSentence": "Die Kings League – der Mix aus Fußball und Entertainment, mit Regeln, die alles verändern und das Spiel auf ein neues Level bringen.",
    "landing.newRegion.secondSentence": "{teamsNumber} Teams, angeführt von den bekanntesten Streamern {countryPrefixSentence} {leagueCountry}. Volle Leidenschaft. Höchstes Tempo. Null Langeweile.",
    "landing.newRegions.countryPrefixTitle.male": "nach",
    "landing.newRegions.countryPrefixTitle.female": "nach",
    "landing.newRegions.countryPrefixSentence.male": "",
    "landing.newRegions.countryPrefixSentence.female": "",
    "landing.newRegions.claimBeforeButton": "Werde ein Spieler der Kings League!",
    "germany": "Deutschland",
    "landing.germany.signup": "Trag dich jetzt ein – und erfahre als Erster, was in der Kings League Germany passiert.",
    "landing.joinDraft": "Trage dich jetzt ein"
  },
  "pt": {
    "landing.newRegion.title": "Das Original kommt endlich {countryPrefixTitle} {leagueCountry}!",
    "landing.newRegion.firstSentence": "Die Kings League – der Mix aus Fußball und Entertainment, mit Regeln, die alles verändern und das Spiel auf ein neues Level bringen.",
    "landing.newRegion.secondSentence": "{teamsNumber} Teams, angeführt von den bekanntesten Streamern {countryPrefixSentence} {leagueCountry}. Volle Leidenschaft. Höchstes Tempo. Null Langeweile.",
    "landing.newRegions.countryPrefixTitle.male": "nach",
    "landing.newRegions.countryPrefixTitle.female": "nach",
    "landing.newRegions.countryPrefixSentence.male": "",
    "landing.newRegions.countryPrefixSentence.female": "",
    "landing.newRegions.claimBeforeButton": "Werde ein Spieler der Kings League!",
    "germany": "Deutschland",
    "landing.germany.signup": "Trag dich jetzt ein – und erfahre als Erster, was in der Kings League Germany passiert.",
    "landing.joinDraft": "Trage dich jetzt ein"
  },
  "fr": {
    "landing.newRegion.title": "Das Original kommt endlich {countryPrefixTitle} {leagueCountry}!",
    "landing.newRegion.firstSentence": "Die Kings League – der Mix aus Fußball und Entertainment, mit Regeln, die alles verändern und das Spiel auf ein neues Level bringen.",
    "landing.newRegion.secondSentence": "{teamsNumber} Teams, angeführt von den bekanntesten Streamern {countryPrefixSentence} {leagueCountry}. Volle Leidenschaft. Höchstes Tempo. Null Langeweile.",
    "landing.newRegions.countryPrefixTitle.male": "nach",
    "landing.newRegions.countryPrefixTitle.female": "nach",
    "landing.newRegions.countryPrefixSentence.male": "",
    "landing.newRegions.countryPrefixSentence.female": "",
    "landing.newRegions.claimBeforeButton": "Werde ein Spieler der Kings League!",
    "germany": "Deutschland",
    "landing.germany.signup": "Trag dich jetzt ein – und erfahre als Erster, was in der Kings League Germany passiert.",
    "landing.joinDraft": "Trage dich jetzt ein"
  }
}
</i18n>
<style scoped>
p {
  @apply mb-4;
}
</style>
