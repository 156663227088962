<!--
// nuxt-ui/components/Shared/standings/TableStandingHome.vue
-->
<script setup lang="ts">
import type { Phase } from "~/src/season-cluster/domain/models/02.Phase";
import type {
  StandingPlayoffColors,
  StandingPositionColor,
} from "~/src/season-cluster/domain/models/01.Season";
import type { GroupedTeamRanking } from "~/src/stats/domain/models/Rankings";
import type { Standing } from "~/src/standing/domain/models/Standing";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { setAliasUrl, slugify } from "~/src/Shared/utils";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import StandingsLegend from "~/nuxt-ui/components/Shared/standings/StandingsLegend.vue";
import seasonService from "~/src/season-cluster/domain/services/seasonService";

const localePath = useLocalePath();
const { getSeasonStanding } = useSeasons();
const { findSeasonStandingPhase } = seasonService();
const { regionParam, isWorldCup, isNations } = useRegions();
const { currentSeason } = useCompetitionStore();
const { contextSiteTitle, webContextGenders } = useWebContext();

const standingPhase = ref<Phase>();
const positionLegend = ref<StandingPlayoffColors[]>();
const secondaryPositionLegend = ref<StandingPlayoffColors[]>();

const getRowColor = (i: number): string | undefined => {
  const colors = positionLegend.value;
  const playoffPosition = colors ? colors[i] : undefined;
  return getRowPositionColorTailwindClass(playoffPosition?.color);
};

const getSecondaryRowColor = (i: number): string | undefined => {
  const colors = secondaryPositionLegend.value;
  const secondaryPlayoffPosition = colors ? colors[i] : undefined;
  return getRowPositionColorTailwindClass(secondaryPlayoffPosition?.color);
};

function getRowPositionColorTailwindClass(playoffColor: StandingPositionColor | undefined) {
  //This function MUST be defined in the Vue file. In any other cases, Tailwind won't render the classes.
  switch (playoffColor) {
    case "green":
      return "before:!bg-emerald-600";
    case "orange":
      return "before:!bg-amber-400";
    case "softOrange":
      return "before:!bg-[#EC7B39]";
    case "red":
      return "before:!bg-red-500";
    case "yellow":
      return "before:!bg-yellow-200";
    case "brown":
      return "before:!bg-[#A8834E]";
    case "gray":
      return "before:!bg-gray-500";
    default:
      return "before:!bg-gray-800";
  }
}

/*const {
  phase,
  positionLegend: legend,
  secondaryPositionLegend: secondaryLegend,
} = currentSeason?.phases ? await getSeasonStanding(currentSeason.id) : undefined;*/

const standingsData = currentSeason.phases
  ? {
      positionLegend: currentSeason.metaInformation?.position_legend,
      secondaryPositionLegend: currentSeason.metaInformation?.secondary_position_legend,
      phase: findSeasonStandingPhase(currentSeason),
    }
  : undefined;

standingPhase.value = standingsData?.phase;
positionLegend.value = standingsData?.positionLegend;
secondaryPositionLegend.value = standingsData?.secondaryPositionLegend;

const worldCupStandings = ref<Standing[]>([]);

if (isWorldCup || isNations) {
  const { data: teamRankings } = await useFetch(
    `/api/stats?season=${currentSeason.id}&type=teams&mainCode=WIN&secondaryCodes=LOSE,GOL,GOL-S&orderBy=ranking&sortOrder=ASC`,
    { query: { ctxGenders: webContextGenders } }
  );

  teamRankings.value
    ? teamRankings.value.forEach(r => worldCupStandings.value.push(transformRankingsIntoStandingFormat(r)))
    : undefined;
}

function transformRankingsIntoStandingFormat(teamRanking: GroupedTeamRanking): Standing {
  return {
    id: teamRanking.teamId,
    team: teamRanking.team,
    rank: teamRanking.rankingData["WIN"].ranking,
    points: null,
    gameTotal: null,
    gameWon: teamRanking.rankingData["WIN"].total,
    gameExtraTimeWon: null,
    gamePenaltyWon: null,
    gameDraw: null,
    gameLost: teamRanking.rankingData["LOSE"].total,
    gameExtraTimeLost: null,
    gamePenaltyLost: null,
    goalPro: teamRanking.rankingData["GOL"].total,
    goalAgainst: teamRanking.rankingData["GOL-S"].total,
    penalty: null,
  };
}
</script>

<template>
  <div v-if="standingPhase && !isWorldCup && !isNations" class="bg-gray-900 p-2.5 sm:p-4 rounded-lg">
    <div v-for="(groupStanding, i) in standingPhase.groups" :key="groupStanding.id">
      <div
        :class="[
          i === 0 ? 'justify-between' : 'justify-end',
          'flex text-gray-400 uppercase text-sm mb-2 items-end',
        ]"
      >
        <div class="flex flex-row gap-2">
          <p v-if="i === 0">{{ $t("tables.banner.title") }}</p>

          <span v-if="standingPhase.groups.length > 1" class="standing-group-title">
            <span class="standing-group-name">{{ groupStanding.name }}</span>
          </span>
        </div>

        <nuxt-link
          v-if="i === 0"
          :to="
            localePath({
              name: `region-Tables`,
              params: { region: regionParam },
            })
          "
          class="normal-case text-primary text-xs"
        >
          {{ $t("cards.stats.seeMore.link") }}
        </nuxt-link>
      </div>
      <hr />
      <div class="header-table-standing">
        <span class="text-left">{{ $t("tables.position.ab") }}</span>
        <span class="col-span-5 text-left">{{ $t("tables.team.word") }}</span>
        <span class="text-center">{{ $t("tables.points.ab") }}</span>
        <span class="text-center">{{ $t("tables.gamesPlayed.ab") }}</span>
        <span class="text-center">{{ $t("tables.goalsDifference.ab") }}</span>
      </div>
      <div
        class="container-standing-rows"
        :class="i === 0 && standingPhase.groups.length > 1 ? 'mb-4' : 'mb-0'"
      >
        <div
          :class="['standing-row']"
          v-for="(standingTeam, i) in groupStanding.standings"
          :key="standingTeam.id"
        >
          <div
            :class="[
              'standing-rank',
              { 'secondary-standing': !!secondaryPositionLegend },
              getSecondaryRowColor(i),
            ]"
          >
            <span :class="['split-standing', getRowColor(i)]"></span>
            <p class="mr-auto">{{ standingTeam.rank }}</p>
          </div>
          <div class="standing-team-container">
            <div class="logo-team-container">
              <NuxtImg
                v-if="standingTeam.team.logo?.url"
                :src="setAliasUrl(standingTeam.team.logo.url)"
                :alt="standingTeam.team.name"
                class="object-contain"
                loading="lazy"
                width="116"
                height="116"
                sizes="28px sm:32px md:44px"
              />
            </div>
            <nuxt-link
              :to="
                localePath({
                  name: 'teams-team-teamName',
                  params: {
                    team: standingTeam.team.id,
                    teamName: slugify(standingTeam.team.name),
                  },
                })
              "
              no-prefetch
            >
              {{ standingTeam.team.name }}
            </nuxt-link>
          </div>
          <span class="standing-table-item-data">{{ standingTeam.points }}</span>
          <span class="standing-table-item-data">{{ standingTeam.gameTotal }}</span>
          <span class="standing-table-item-data rounded-r-md">
            {{ standingTeam.goalPro - standingTeam.goalAgainst }}
          </span>
        </div>
      </div>

      <div
        v-if="i === standingPhase?.groups.length - 1"
        class="flex flex-wrap items center justify-center gap-6 mt-6"
      >
        <standings-legend
          v-if="!!positionLegend && positionLegend.length"
          :legend="positionLegend"
          :grouped-colors="!!secondaryPositionLegend"
          :is-home-legend="!secondaryPositionLegend"
          ><span class="flex text-gray-400 uppercase text-sm">{{ contextSiteTitle }}</span>
        </standings-legend>
        <standings-legend
          v-if="!!secondaryPositionLegend && secondaryPositionLegend.length"
          :legend="secondaryPositionLegend"
          grouped-colors
        >
          <span class="flex text-gray-400 uppercase text-sm">KWC</span>
        </standings-legend>
      </div>
    </div>
  </div>

  <!--  standings for world-cup competitions-->
  <div
    v-else-if="(isWorldCup || isNations) && worldCupStandings.length"
    class="bg-gray-900 p-2.5 sm:p-4 rounded-lg"
  >
    <div>
      <div class="flex text-gray-400 uppercase text-sm mb-2 justify-between items-end">
        <p>{{ $t("tables.banner.title") }}</p>
        <!--        <span v-if="standingPhase.groups.length > 1" class="standing-group-title"-->
        <!--          >{{ $t("general.group.word") }}-->
        <!--          <span class="standing-group-name">{{ groupStanding.name }}</span></span-->
        <!--        >-->

        <nuxt-link
          :to="
            localePath({
              name: `region-Tables`,
              params: { region: regionParam },
            })
          "
          class="normal-case text-primary text-xs"
        >
          {{ $t("cards.stats.seeMore.link") }}
        </nuxt-link>
      </div>
      <hr />
      <div class="header-table-standing">
        <!--        <span class="text-left">{{ $t("tables.position.ab") }}</span>-->
        <span class="col-span-6 text-left">{{ $t("tables.team.word") }}</span>
        <span class="text-center">{{ $t("tables.wins.ab") }}</span>
        <span class="text-center">{{ $t("tables.lost.ab") }}</span>
        <span class="text-center">{{ $t("tables.goalsDifference.ab") }}</span>
      </div>
      <div class="container-standing-rows mb-0">
        <div
          :class="['standing-row']"
          v-for="standingTeam in worldCupStandings.slice(0, 12)"
          :key="standingTeam.id"
        >
          <!--          <div-->
          <!--            :class="[-->
          <!--              'standing-rank',-->
          <!--              { 'secondary-standing': !!secondaryPositionLegend },-->
          <!--              getSecondaryRowColor(i),-->
          <!--            ]"-->
          <!--          >-->
          <!--            <span :class="['split-standing', getRowColor(i)]"></span>-->
          <!--            <p class="mr-auto">{{ standingTeam.rank }}</p>-->
          <!--          </div>-->
          <div class="standing-team-container world-cup-standing">
            <div class="logo-team-container">
              <NuxtImg
                v-if="standingTeam.team.logo?.url"
                :src="setAliasUrl(standingTeam.team.logo.url)"
                :alt="standingTeam.team.name"
                class="object-contain"
                loading="lazy"
                width="116"
                height="116"
                sizes="28px sm:32px md:44px"
              />
            </div>
            <nuxt-link
              :to="
                localePath({
                  name: 'teams-team-teamName',
                  params: {
                    team: standingTeam.team.id,
                    teamName: slugify(standingTeam.team.name),
                  },
                })
              "
              no-prefetch
            >
              {{ standingTeam.team.name }}
            </nuxt-link>
          </div>
          <span class="standing-table-item-data">{{ standingTeam.gameWon }}</span>
          <span class="standing-table-item-data">{{ standingTeam.gameLost }}</span>
          <span class="standing-table-item-data rounded-r-md">
            {{ standingTeam.goalPro - standingTeam.goalAgainst }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.standing-group-title {
  @apply text-primary flex justify-center items-center text-[10px] gap-0.5;
}

.standing-group-name {
  @apply px-1.5 rounded border border-primary uppercase text-[10px] font-normal text-primary leading-[17px] ml-0.5;
}

.header-table-standing {
  @apply grid grid-cols-[8%_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr] lg:grid-cols-9 text-gray-400 text-[10px] uppercase my-3;
}

.standing-row {
  @apply h-12 lg:h-[85px] grid grid-cols-[8%_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr] lg:grid-cols-9 text-sm text-center font-bold mb-1;
}

.standing-rank {
  @apply h-full w-full flex items-center mr-auto;
}

.secondary-standing {
  @apply before:min-w-[2px] before:sm:min-w-[3px] before:bg-gray-800 before:h-full before:rounded-sm;
}

span.split-standing {
  @apply h-full ml-0.5 mr-auto;
}

span.split-standing::before {
  content: "";
  @apply min-w-[2px] sm:min-w-[3px] bg-gray-800 h-full rounded-sm mr-auto block;
}

/*.container-standing-rows > div:nth-child(-n + 4) .standing-rank::before {
  @apply bg-emerald-600;
}*/

.standing-team-container {
  @apply col-span-5 text-left flex justify-start gap-3 items-center bg-gray-800 rounded-l-md h-full pl-1;
}

.standing-team-container.world-cup-standing {
  @apply !col-span-6;
}

.logo-team-container {
  @apply h-8 w-8 lg:w-11 lg:h-11 flex justify-center items-center;
}

.standing-table-item-data {
  @apply text-center bg-gray-800 h-full flex justify-center items-center;
}
</style>
