<!--
// nuxt-ui/components/pages/region-home/BannerFullWidthCms.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import bannerUiService from "~/src/content/infrastructure/ui-services/bannerUiService";
import type { BannerHome } from "~/src/content/domain/models/Banner";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { webContext } = useWebContext();
const { isWorldCup, isNations } = useRegions();
const { getHomeTopBanner } = bannerUiService();
const { locale } = useI18n();

const homeBanner = ref<BannerHome | undefined>();

homeBanner.value = await getHomeTopBanner(locale.value);
</script>

<template>
  <section
    v-if="
      !!homeBanner &&
      homeBanner.webContext.includes(isWorldCup ? 'world-cup' : isNations ? 'kwc-nations' : webContext) &&
      !homeBanner.isDouble
    "
  >
    <component
      :is="homeBanner.link !== '' ? 'a' : 'div'"
      :href="homeBanner.link"
      :target="homeBanner.linkTarget !== '' ? homeBanner.linkTarget : '_self'"
      class="justify-center items-center max-w-full bg-cover bg-center md:max-h-[117px] flex md:hidden mobile-banner"
      :style="`background-image: url('${homeBanner.imageBgMobile}')`"
    >
      <div class="flex justify-center items-center max-w-full px-2 h-full">
        <nuxt-img
          v-if="homeBanner.imageMobile"
          :src="homeBanner.imageMobile"
          :alt="homeBanner.description || ''"
          width="1025"
          height="345"
          sizes="435px md:1025px"
          fit="outside"
          class="object-contain max-h-full"
        />
      </div>
    </component>
    <component
      :is="homeBanner.link !== '' ? 'a' : 'div'"
      :href="homeBanner.link"
      :target="homeBanner.linkTarget !== '' ? homeBanner.linkTarget : '_self'"
      class="justify-center items-center max-w-full bg-cover bg-center md:max-h-[117px] hidden md:flex"
      :style="`background-image: url('${homeBanner.imageBgDesktop}')`"
    >
      <div class="flex justify-center items-center max-w-[1420px] px-2 h-full md:max-h-[117px]">
        <nuxt-img
          v-if="homeBanner.imageDesktop"
          :src="homeBanner.imageDesktop"
          :alt="homeBanner.description || ''"
          width="1420"
          height="117"
          fit="outside"
          class="object-contain max-h-[117px]"
        />
      </div>
    </component>
  </section>

  <section
    v-if="
      !!homeBanner &&
      homeBanner.webContext.includes(isWorldCup ? 'world-cup' : isNations ? 'kwc-nations' : webContext) &&
      homeBanner.isDouble
    "
  >
    <div
      class="max-w-full lg:max-h-[117px] overflow-hidden flex flex-col bigsm:flex-row items-center justify-center"
    >
      <component
        v-if="homeBanner.imageLeft && homeBanner.imageRight"
        :is="homeBanner.linkLeft !== '' ? 'a' : 'div'"
        :href="homeBanner.linkLeft"
        :target="homeBanner.linkLeftTarget !== '' ? homeBanner.linkLeftTarget : '_self'"
        class="flex justify-center items-center w-full bigsm:w-1/2 bg-cover bg-center bigsm:max-h-[117px] bigsm:!justify-end"
        :style="`background-image: url('${homeBanner.imageBgLeft}')`"
      >
        <div
          class="flex items-center justify-center w-full px-2 bigsm:max-w-[720px] h-full bigsm:max-h-[117px]"
        >
          <nuxt-img
            :src="homeBanner.imageLeft"
            :alt="homeBanner.descriptionLeft || ''"
            width="720"
            height="117"
            sizes="435px md:720px"
            fit="outside"
            class="object-contain"
          />
        </div>
      </component>

      <component
        v-if="homeBanner.imageLeft && homeBanner.imageRight"
        :is="homeBanner.linkRight !== '' ? 'a' : 'div'"
        :href="homeBanner.linkRight"
        :target="homeBanner.linkRightTarget !== '' ? homeBanner.linkRightTarget : '_self'"
        class="flex justify-center items-center w-full bigsm:w-1/2 bg-cover bg-center bigsm:max-h-[117px] bigsm:!justify-start"
        :style="`background-image: url('${homeBanner.imageBgRight}')`"
      >
        <div
          class="flex items-center justify-center w-full px-2 bigsm:max-w-[720px] h-full bigsm:max-h-[117px]"
        >
          <nuxt-img
            :src="homeBanner.imageRight"
            :alt="homeBanner.descriptionRight || ''"
            width="720"
            height="117"
            sizes="435px md:720px"
            fit="outside"
            class="object-contain"
          />
        </div>
      </component>
    </div>
  </section>
</template>

<style scoped></style>
