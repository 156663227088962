<!--
// nuxt-ui/components/pages/region-home/SectionVideosYoutubeHome.vue
-->
<script setup lang="ts">
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import type { YoutubeVideo } from "~/src/youtube/domain/models/YoutubeVideo";
import { useYoutube } from "~/nuxt-ui/Shared/composables/youtube";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import type { SocialMedia } from "~/src/region/domain/models/Region";
import SliderArrowsBehaviour from "~/nuxt-ui/components/Shared/slider-matches/SliderArrowsBehaviour.vue";

const { params } = useRoute();
const { getYoutubeChannelData } = useYoutube();
const mainVideo = ref<YoutubeVideo>();
const moreVideos = ref<YoutubeVideo[]>([]);
const { region } = useCompetitionStore();
const { webContext } = useWebContext();

const regionSocial = region ? region.social : undefined;
const youtubeProfile: SocialMedia | undefined = regionSocial
  ? regionSocial.find(s => s.name === "youtube")
  : undefined;

const youtubeData = await getYoutubeChannelData();

if (youtubeData && youtubeData.videos?.length) {
  const [main, ...others] = youtubeData.videos;
  mainVideo.value = main;
  moreVideos.value = others;
}
</script>

<template>
  <section v-if="youtubeData && youtubeData.videos.length" class="videos-section">
    <div v-if="mainVideo" class="main-video-container">
      <iframe
        loading="lazy"
        width="100%"
        height="100%"
        class="max-w-[1153px]"
        :src="`https://www.youtube-nocookie.com/embed/${mainVideo.video_id}`"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div v-if="moreVideos?.length" class="max-w-full md:px-24 lg:px-36 hidden md:block">
      <slider-arrows-behaviour
        button-right-id="buttonVideoRight"
        button-left-id="buttonVideoLeft"
        :button-scroll-distance="218"
        slider-container-id="videoSlider"
        outer-arrows
        :conditionally-render-if="moreVideos.length > 4"
      >
        <div id="videoSlider" class="extra-videos-slider-container hide-scrollbar">
          <iframe
            loading="lazy"
            v-for="video in moreVideos"
            width="218"
            height="123"
            class="rounded-lg"
            :src="`https://www.youtube-nocookie.com/embed/${video.video_id}`"
            :title="video.title"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </slider-arrows-behaviour>
    </div>

    <div v-if="moreVideos?.length" id="videoGridMob" class="extra-videos-grid-container-mob">
      <iframe
        v-for="video in moreVideos.slice(0, 3)"
        loading="lazy"
        width="100%"
        height="218"
        :src="`https://www.youtube-nocookie.com/embed/${video.video_id}`"
        :title="video.title"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        class="h-[218px] sm:h-[312px]"
      ></iframe>
    </div>

    <div v-if="youtubeProfile" class="w-full flex justify-center items-center mt-10">
      <base-nuxt-link :to="youtubeProfile.url" target="_blank" text-size="text-xs" primary>
        <span class="flex justify-between items-center gap-x-2">
          <svg
            class="w-3 h-3"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="YouTube">
              <g id="Vector">
                <path
                  d="M11.27 4.2559C11.2078 4.02188 11.0852 3.8083 10.9146 3.63653C10.7439 3.46477 10.5311 3.34084 10.2975 3.27715C9.4375 3.0459 6 3.0459 6 3.0459C6 3.0459 2.5625 3.0459 1.7025 3.27715C1.46888 3.34084 1.25609 3.46477 1.08542 3.63653C0.914753 3.8083 0.79219 4.02188 0.73 4.2559C0.5 5.11965 0.5 6.9209 0.5 6.9209C0.5 6.9209 0.5 8.72215 0.73 9.5859C0.79219 9.81992 0.914753 10.0335 1.08542 10.2053C1.25609 10.377 1.46888 10.501 1.7025 10.5646C2.5625 10.7959 6 10.7959 6 10.7959C6 10.7959 9.4375 10.7959 10.2975 10.5646C10.5311 10.501 10.7439 10.377 10.9146 10.2053C11.0852 10.0335 11.2078 9.81992 11.27 9.5859C11.5 8.72215 11.5 6.9209 11.5 6.9209C11.5 6.9209 11.5 5.11965 11.27 4.2559Z"
                  fill="#FF0302"
                />
                <path d="M4.87501 8.55654V5.28529L7.75001 6.92091L4.87501 8.55654Z" fill="white" />
              </g>
            </g>
          </svg>
          {{ $t("youTube.moreVideosBtn") }}
        </span>
      </base-nuxt-link>
    </div>
  </section>
</template>

<style scoped>
.videos-section {
  @apply bg-gradient-to-b from-primary-dark to-black pt-0 md:pt-24 pb-28 px-0 flex flex-col items-center;
}

.main-video-container {
  @apply w-full h-[211px] md:px-24 lg:px-36 lg:h-[649px] md:h-[500px] sm:h-[350px] flex flex-col items-center;
}

.extra-videos-slider-container {
  @apply flex max-w-[1153px] items-center justify-between gap-x-4 mt-6 overflow-x-scroll;
}

.extra-videos-grid-container-mob {
  @apply grid grid-cols-1 md:hidden w-full gap-3 mt-4 px-4;
}
</style>
