<!--
// nuxt-ui/components/Shared/stats/GridFeaturedStatsHome.vue
-->
<script setup lang="ts">
import type { SeasonStatsPartner } from "~/src/season-cluster/domain/models/01.Season";
import type { RankingsData, TurnMvpPlayer } from "~/src/player/domain/Player";
import type { BannerCard } from "~/src/content/domain/models/Banner";
import CardPlayerMvp from "~/nuxt-ui/components/pages/region-stats/CardPlayerMvp.vue";
import CardStoreAd from "~/nuxt-ui/components/Shared/CardStoreAd.vue";
import { usePlayers } from "~/nuxt-ui/Shared/composables/players";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import bannerUiService from "~/src/content/infrastructure/ui-services/bannerUiService";
import CardPlayerRankingGeneralNoPartner from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardPlayerRankingGeneralNoPartner.vue";
import CardPlayerRankingCards from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardPlayerRankingCards.vue";
import CardBestGoalkeeper from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardBestGoalkeeper.vue";

const { getLastTurnMvp } = usePlayers();
const { useFetchParamsSeasonStatsFirstPlayer } = useSeasons();
const { regionParam, isSpain, isWorldCup, isNations } = useRegions();
const { webContext } = useWebContext();
const { currentSeason } = useCompetitionStore();
const { getCardBanner } = bannerUiService();
const localePath = useLocalePath();
const { locale } = useI18n();

const mvpPartner: SeasonStatsPartner | undefined = currentSeason?.metaInformation?.stats_partner
  ? currentSeason?.metaInformation?.stats_partner
  : undefined;

const turnMvp = ref<TurnMvpPlayer | undefined>();
const topMvps = ref<RankingsData | undefined>();

const storeBanner = ref<BannerCard | undefined>();

if (isWorldCup || isNations) {
  const fetchOptsMvps = useFetchParamsSeasonStatsFirstPlayer(currentSeason.id, "MVP");
  if (fetchOptsMvps) {
    const { path: pathMvps, opts: optsMvps } = fetchOptsMvps;
    const { data } = await useFetch(pathMvps, optsMvps);
    topMvps.value = data.value;
  }
} else {
  turnMvp.value =
    currentSeason && currentSeason?.id !== 13 ? await getLastTurnMvp(currentSeason.id) : undefined;
}

onMounted(async () => {
  await nextTick();
  storeBanner.value = await getCardBanner("home", locale.value);
});
</script>

<template>
  <div class="md:bg-gray-900 mt-8 md:p-4 rounded-lg lg:mt-0">
    <div class="px-4 md:px-0">
      <div class="flex text-gray-400 uppercase text-sm mb-2 justify-between items-end">
        <p>{{ $t("general.stats.title") }}</p>
        <nuxt-link
          :to="
            localePath({
              name: `region-Stats`,
              params: { region: regionParam },
            })
          "
          class="normal-case text-primary text-xs"
        >
          {{ $t("cards.stats.seeMore.link") }}
        </nuxt-link>
      </div>
      <hr />
    </div>

    <div class="overflow-x-scroll overflow-hidden lg:overflow-visible max-w-full hide-scrollbar">
      <div
        :class="[
          isNations ? 'container-cards-row' : 'main-container-cards',
          isNations
            ? 'grid-cols-[repeat(4,minmax(0,239px))] sm:grid-cols-[repeat(4,minmax(0,333px))]'
            : storeBanner
              ? 'grid-cols-[repeat(5,minmax(0,239px))] sm:grid-cols-[repeat(5,minmax(0,333px))]'
              : 'grid-cols-[repeat(6,minmax(0,239px))] sm:grid-cols-[repeat(6,minmax(0,333px))]',
        ]"
      >
        <!--Mvp cards-->
        <card-player-mvp
          v-if="isWorldCup || isNations"
          :featured-player-image="topMvps?.player.image?.url"
          :featured-player-name="topMvps?.player.shortName"
          :featured-player-team-image="topMvps?.team.logo?.url"
          :featured-player-stat="topMvps?.total"
          :turn-number="topMvps?.total"
          :has-partner="!!mvpPartner"
          :partner-logo="mvpPartner?.logo"
          :partner-name="mvpPartner?.name"
          :table-stat-title="$t('general.stats.mvps')"
          page-stats-link="Mvp"
          hide-turn
        ></card-player-mvp>
        <card-player-mvp
          v-else-if="!isWorldCup && !isNations"
          :featured-player-image="turnMvp?.player?.image?.url"
          :featured-player-name="turnMvp?.player?.shortName"
          :featured-player-team-image="turnMvp?.team?.logo?.url"
          :featured-player-stat="1"
          :turn-number="turnMvp?.turnName"
          :has-partner="!!mvpPartner"
          :partner-logo="mvpPartner?.logo"
          :partner-name="mvpPartner?.name"
          :table-stat-title="$t('general.stats.mvps')"
          page-stats-link="TurnMvp"
          hide-stat
        ></card-player-mvp>
        <!--end mvp cards-->

        <card-player-ranking-general-no-partner
          v-if="!!currentSeason"
          :season-id="currentSeason.id"
          page-stats-link="GoalsGrouped"
          parameter-string="GOL-WOP"
          :pre-title-stat="locale === 'it' ? $t('cards.stats.pretitle.best') : $t('cards.stats.pretitle.top')"
          :title-stat="$t('cards.stats.title.scorer')"
          :table-stat-title="$t('general.stats.goals')"
        />

        <card-player-ranking-general-no-partner
          v-if="!!currentSeason"
          :season-id="currentSeason.id"
          page-stats-link="Passing"
          parameter-string="ASS-V"
          :pre-title-stat="$t('cards.stats.pretitle.top')"
          :title-stat="$t('cards.stats.title.assistant')"
          :table-stat-title="$t('general.stats.assists')"
        />

        <card-best-goalkeeper
          v-if="!!currentSeason && !isNations"
          :season-id="currentSeason.id"
          page-stats-link="Goalkeeper"
          :disclaimer="isWorldCup ? $t('cards.stats.disclaimer.bestGkKWC') : undefined"
        />

        <card-player-ranking-cards v-if="!!currentSeason" :season-id="currentSeason.id" />

        <div
          v-if="
            storeBanner &&
            storeBanner.image &&
            storeBanner.webContext.includes(isWorldCup ? 'world-cup' : isNations ? 'kwc-nations' : webContext)
          "
          :class="['hidden lg:block h-full w-full', { 'lg:hidden': isNations }]"
        >
          <card-store-ad
            light
            :product-image="storeBanner.image"
            :product-title="storeBanner.description"
            :product-link="storeBanner.link"
            :product-link-target="storeBanner.linkTarget"
            title-card="Tienda oficial"
          ></card-store-ad>
        </div>
        <card-player-ranking-general-no-partner
          v-else-if="!!currentSeason && !isNations"
          :season-id="currentSeason.id"
          page-stats-link="Offensives"
          parameter-string="DRB"
          :title-stat="$t('cards.stats.title.dribbling')"
          :table-stat-title="$t('Drib')"
          is-stats
        />
      </div>
    </div>
  </div>

  <div
    v-if="
      storeBanner &&
      storeBanner.image &&
      storeBanner.webContext.includes(isWorldCup ? 'world-cup' : isNations ? 'kwc-nations' : webContext)
    "
    class="my-4 max-w-[333px] mx-auto sm:hidden"
  >
    <card-store-ad
      light
      :product-image="storeBanner.image"
      :product-title="storeBanner.description"
      :product-link="storeBanner.link"
      :product-link-target="storeBanner.linkTarget"
      title-card="Tienda oficial"
    ></card-store-ad>
  </div>
</template>

<style scoped>
.container-cards-row {
  @apply grid justify-items-center min-w-max lg:grid-cols-4 lg:grid-rows-none gap-x-4 mt-4 px-4 md:px-0;
}

.main-container-cards {
  @apply grid justify-items-center min-w-max lg:grid-cols-3 lg:grid-rows-[repeat(2,minmax(0,568px))] gap-x-4;
  @apply gap-y-[35px] mt-4 px-4 md:px-0;
}
</style>
