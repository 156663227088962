<!--
// nuxt-ui/components/pages/region-home/KwcTeamsImageHome.vue
-->
<script setup lang="ts">
import { setAliasUrl } from "~/src/Shared/utils";

const localePath = useLocalePath();

const { data, error } = await useFetch("/api/cms/kwcc-qualified-home");
if (error.value) console.error(error.value);
</script>

<template>
  <section v-if="data" class="kql-w-container py-6 xl:px-0 grid grid-cols-1">
    <div class="md:bg-gray-900 mt-8 md:p-4 rounded-lg lg:mt-0">
      <div class="px-4 md:px-0">
        <div class="flex text-gray-400 uppercase text-sm mb-2 justify-between items-end">
          <p>KWC Clubs 2025</p>
          <nuxt-link
            :to="
              localePath({
                name: `region-Teams`,
                params: { region: 'world-cup' },
              })
            "
            class="normal-case text-primary text-xs"
          >
            {{ $t("cards.stats.seeMore.link") }}
          </nuxt-link>
        </div>
        <hr />

        <nuxt-img
          :src="setAliasUrl(data.desktop ? data.desktop : data.mobile)"
          alt="KWCC Qualified Teams"
          class="hidden md:block mt-4 w-full max-w-6xl h-auto max-h-[840px] object-contain object-center mx-auto"
          fit="outside"
          width="1920"
          height="1080"
        />
        <nuxt-img
          :src="setAliasUrl(data.mobile ? data.mobile : data.desktop)"
          alt="KWCC Qualified Teams"
          class="md:hidden mt-4 w-full max-w-full h-auto max-h-[580px] object-contain"
          fit="outside"
          width="1080"
          height="1350"
        />
      </div>
    </div>
  </section>
</template>

<style scoped></style>
